
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


.sidenav .app-brand {
  height: 66px;
  padding-right: 1rem;
  padding-left: 1rem;
}

tr.border-bottom td {
  border-bottom: 2pt solid #000000;
}

.navbar-brand {
  margin: auto;
}

.app-brand-text {
  font-size: 18px;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}


.btn-sedna, .btn-sedna:hover, .btn-sedna:focus, .btn-sedna:active, .btn-sedna.active, .open>.dropdown-toggle.btn-sedna {
  color: #fff;
  background-color: #b67520;
  border-color: #b67520; /*set the color you want here*/
}

.sedna-menu-bar {
  color: #F5F5F5FF
}

body {
  background-color: #ffffff
}


.authentication-wrapper {
  justify-content: center;
  display: flex;
}

.authentication-inner {
  max-width: 380px;
  width: 100%;
}

//YellowBasket Additions //

/* Buttons */
.link-btn {
    border: none;
    background-color: transparent;
}

.link-btn:hover {
    opacity: 80%;
    transition: 0.3s ease-in-out;
}

.btn-outline-secondary {
    color: #848891;
}

.btn-outline-secondary:hover {
    color: white;
}

// End of Buttons //

// Modal //

.modal-header {
    color: white;
    background-color: #40445c;
}

.modal-body {
    background-color: #1D2839;
    color: white;
}

.modal-footer {
    background-color: #1D2839;
    color: white;
}

//Project Overview Table Navigation //
.nav {
    border-bottom: solid .5px grey;
}

.nav-tabs .nav-link {
    border-color: grey;
    margin-right: 10px;
    color: rgb(77, 77, 77);
}

.nav-link.active {
    border-color: grey !important;
    font-weight: bold;
}

.nav-link:hover {
    border-color: rgb(149, 149, 149) !important;
    border-bottom: none;
}

.nav-link {
    text-align: center;
    padding: 5px 5px 5px 5px;
}

.nav-items {
    border: 1px solid grey;
}

/* End of Project Overview Table Nav Styles */

/* Start of Project Overview Table Styles */

/* Custom table row colors */
.table-striped tbody tr:nth-child(odd) {
    background-color: white;
    /* Set the background color for odd rows to white */
}

.table-striped tbody tr:nth-child(even) {
    background-color: rgb(243, 243, 243);
    /* Set the background color for even rows to light gray */
}

/* Start of Modal Styles on Opportunity page*/

.card-shadow {
  border-radius: 8px;
  background-color: white;
  filter: drop-shadow(2px 3px 8px rgb(191, 191, 191));
  padding: 10px;
}

// Scrolling Effects

.scroll-nobar::-webkit-scrollbar {
  display: none;
}

.scroll-nobar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow: hidden;
  overflow: auto;
}

// Card Elements

.card-element {
    border-radius: 8px;
    margin-top: 18px;
    padding: 10px;
}

.progress-container {
    width: 100%;
    height: 5px;
    background-color: #fff;
    overflow: hidden;
}

.progress-bar {
    width: 100%;
    height: 100%;
    background-color: var(--yellow);
    animation: progress-animation 2s linear infinite;
    transform: translateX(-100%);
}

/*.canvas-container {
    height: 60vh;
}*/

.chart-cat-btn {
    background-color: transparent;
    border-radius: 20px;
    border-color: white;
    position: relative;
}

.chart-cat-btn-disabled {
    background-color: transparent;
    border-radius: 20px;
    border-color: gray;
    position: relative;
    opacity: 0.7;
}

.chart-cat-btn input[type='radio'] {
    display: none;
}

.chart-cat-btn-disabled input[type='radio'] {
    display: none;
}

.chart-cat-btn input[type='radio']:disabled + label {
    background-color: transparent;
    border-color: gray;
    opacity: 0.7;
}

.chart-cat-btn:focus {
    background-color: rgb(191, 102, 63);
}

.chart-select-btn {
    border-bottom: 1px solid grey;
    background-color: transparent;
    width: 100%;
    height: 7vh;
    color: white;
    text-align: left;
    font-size: 15px;
    letter-spacing: 2px;
    opacity: 90%;
}

.chart-select-btn:focus {
    background-color: rgb(204,204,204, 0.3) ;
    color: white;
}

.chart-dropdown-btn {
    color: white;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;

}

 // Context Button
.fixed-button {
    position: fixed;
    bottom: 20px; /* 20px from the bottom of the viewport */
    right: 20px; /* 20px from the right of the viewport */
    padding: 10px 20px;
    background-color: #BF663F;
    color: black;
    border: none;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    cursor: pointer;
    z-index: 1000; /* Ensures the button stays above other elements */
}

.fixed-button:hover {
    background-color: #0056b3;
}

// Modals

.modal {
  overflow: auto;
}

#upload-project-details label {
  font-weight: 650;
}
#upload-project-spreadsheet:hover {
  background-color: #E2E6EA!important;
}

  // Styling for Table Overlay Buttons

  .table thead th {
    border-bottom: none !important;
  }

  #table-sample .column-8,
  #table-sample .column-9,
  #table-sample .column-10,
  #table-sample .column-11,
  #table-sample .column-12 {
    background-color: rgba(0, 0, 0, 0.10);
    color: grey;
  }

  .hovered {
    background-color: transparent!important;
    color: black!important;
  }

$grid-breakpoints: ( xs: 0, sm: 600px, md: 800px, lg: 1000px, xl: 1400px );
  