
$font-size-base: .894rem !default;

$h1-font-size: 2.25rem !default;
$h2-font-size: 1.813rem !default;
$h3-font-size: 1.563rem !default;
$h4-font-size: 1.313rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: $font-size-base !default;


$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-margin-bottom: 1rem !default;


body {
    font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: .894rem !important;
    font-weight: 400 !important;
    line-height: 1.47 !important;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    // font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    // color: $headings-color;
}

h1, .h1 {
    font-size: $h1-font-size;
}

h2, .h2 {
    font-size: $h2-font-size;
}

h3, .h3 {
    font-size: $h3-font-size;
}

h4, .h4 {
    font-size: $h4-font-size;
}

h5, .h5 {
    font-size: $h5-font-size;
}

h6, .h6 {
    font-size: $h6-font-size;
}


.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


// Color Themes
body.dark {
    --background-gradient: linear-gradient(to right, #222D3E, #222D3E);
    --primary-background: #39445C;
    --secondary-background: #1D2839 !important;
    --sidebar-background: #313C4C;
    --sidebar-icons: #666e7e;
    --sidebar-btn-text: #adb6bd;
    --sidebar-link-hover: #4f5869;
    --font-color: white;
    --card-border: transparent;
    --button-navigation: #39445C;
    --graph-selector: rgba(255, 255, 255, 0.25);
}


body.light {
    --background-gradient: linear-gradient(#f8f8f8, #ebebeb);
    --primary-background: white;
    --secondary-background: #e8e8e8;
    --sidebar-background: #2e2e2f;
    --sidebar-icons: #827e7e;
    --sidebar-btn-text: #929292;
    --sidebar-link-hover: #5e5e5e;
    --font-color: black;
    --card-border: lightgrey;
    --button-navigation: #114c97;
    --graph-selector: rgba(109, 109, 109, 0.25);
}

#page-container {
    height: 100vh;
}

.mobile-d-none {
    display: flex;
}

@media (max-width: 767px) {
    .mobile-d-none {
        display: none;
    }
}

.card-element {
    border-radius: 8px;
    background-color: var(--primary-background);
    border: solid 1px var(--card-border);
    margin-top: 18px;
    padding: 10px;
}

.light {
    font-weight: 100;
}



.card-header {
    background-color: var(--primary-background);
}

.card-body.accordian {
    background-color: var(--secondary-background);
}



/* start of graph section */

.graph-btn {
    color: var(--font-color);
    width: 100%;
    background-color: Transparent;
    border-radius: 2px;
    letter-spacing: 2px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 0px 6px 20px;
    border: none;
    border-radius: 12px;
    border-bottom: solid 0.5px var(--graph-selector);
}

.graph-btn:hover {
    background-color: var(--graph-selector);
}

.graph-btn.clicked {
    background-color: var(--graph-selector);
}

.dropdown-menu {
    position: absolute;
    transform: translate3d(-100px, 29px, 0px) !important;
    top: 0px;
    left: -100px;
    will-change: transform;
}


.input-group-text-mod {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-left: none !important;
    background-color: white;
    height: 31px;
    margin-left: -2px;
}

.button-nav {
    background-color: var(--button-navigation);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 3px 30px 5px 30px;
}

/* End of color group  */


@media (min-width: 576px) {
    .brand {
        max-width: 300px;
    }
}


/* Table Styles */


/* card table */

table.report td {
    font-weight: 500;
}



.table-striped-custom {
    color: var(--font-color);
    border-style: none;
    border: none;
    font-size: 12px;
}

.table-striped-custom thead {
    background-color: var(--secondary-background);
    color: var(--font-color);
}

.table-striped-custom tbody,
.table-striped-custom td,
.table-striped-custom tfoot,
.table-striped-custom th,
.table-striped-custom thead,
.table-striped-custom tr {
    border-color: none;
    border-style: none;
    padding: 6px 8px 6px 8px;
}

.table-striped-custom tbody {
    border-style: none !important;
}

.table-striped-custom tbody tr:nth-of-type(odd) {
    background-color: var(--primary-background);
    border: none;
    color: var(--font-color);
}

.table-striped-custom tbody tr:nth-of-type(even) {
    background-color: var(--secondary-background);
    border: none;
    color: var(--font-color)
}

// Sidebar

/* Add hover effect to sidebar icons */
// .sidebar-icons:hover {
//   background-color: #f5f5f5;
//   cursor: pointer;
// }


#sidebar {
    max-width: 40px;
    transition: max-width 0.5s ease-in-out;
}

/*#sidebar:hover {
    max-width: 175px;
    transition: max-width 0.4s ease-in-out;
}*/

#sidebar.expanded {
    max-width: 175px;
    transition: max-width 0.4s ease-in-out;
}




.sidebar-link {
    color: var(--sidebar-icons);
    cursor: pointer;
    padding: 5px;
    width: 20px;
    transition: 0.3s ease-in-out;
    margin-top: 20px;
    max-height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.expanded .sidebar-link {
    width: 140px;
    transition: 0.5s ease-in-out;
    padding-left: 8px !important;
}


.sidebar-link button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 19px;
    text-transform: uppercase;
    padding-left: 12px
}

.sidebar-link:hover {
    color: var(--sidebar-btn-text);
    transition: color 0.5s ease-in-out;
    background-color: var(--sidebar-link-hover);
    border-radius: 5px;
}





/* Initially hidden elements */
.nav-btn.hidden {
    opacity: 0;
    width: 0px;
    visibility: hidden;
    transition: opacity 0.0s ease-out, visibility 0.0s 0s; /* Quick ease-out */
}

/* Visible elements */
.nav-btn:not(.hidden) {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.9s ease-in, visibility 0.9s 0s; /* Slow Ease out */
}



.divider {
    border-top: 1px solid #666e7e;
    opacity: 40%;
}

// Dropdowns








body.dark .switcher-indicator {
    outline: solid 1px #fff;

}


.switcher {
    position: relative;
    display: inline-block;
    margin-right: .75rem;
    margin-bottom: 0;
    border-radius: 60rem;
    vertical-align: middle;
    font-weight: normal;
    cursor: default;
    padding-left: 2.375rem;
    min-height: 1.375rem;
    font-size: .894rem;
    line-height: 1.4
}

.switcher .switcher-indicator {
    width: 2.375rem;
    height: 1.375rem;
    font-size: .625rem;
    line-height: 1.375rem;
    top: 0
}

.switcher .switcher-label {
    top: .0617rem
}

.switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: 1rem
}

.switcher .switcher-indicator::after {
    top: 0;
    margin: .25rem 0 0 .25rem;
    width: .875rem;
    height: .875rem
}

.switcher .switcher-no {
    padding-right: .25rem;
    padding-left: 1.125rem
}

.switcher .switcher-yes {
    padding-right: 1.125rem;
    padding-left: .25rem
}


.switcher-input {
    position: absolute;
    z-index: -1;
    margin: 0;
    padding: 0;
    opacity: 0
}

.switcher-indicator {
    position: absolute;
    left: 0;
    overflow: hidden;
    border-radius: 60rem;
    background: rgba(24,28,33,0.1);
    color: rgba(24,28,33,0.3);
    font-weight: bold;
    cursor: default;
    transition-duration: .2s;
    transition-property: left, right, background, box-shadow;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.switcher-label {
    position: relative;
    display: inline-block;
    padding-left: .5rem;
    /*color: #4E5155;*/
    font-weight: 400;
    cursor: default
}

.switcher-no, .switcher-yes {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    transition-duration: .2s;
    transition-property: left, right
}


.switcher-no {
    left: 0
}

.switcher-yes {
    left: -100%
}

.switcher-input:not(:checked) ~ .switcher-indicator .switcher-yes {
    color: transparent
}

.switcher-input:checked ~ .switcher-indicator .switcher-no {
    left: 100%;
    color: transparent
}


.switcher-input:checked ~ .switcher-indicator .switcher-yes {
    left: 0
}

.switcher-indicator::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    border-radius: 999px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(24,28,33,0.04),0 1px 6px rgba(24,28,33,0.09);
    transition-duration: .2s;
    transition-property: left, right, background
}


.switcher-input:disabled ~ .switcher-indicator {
    opacity: .5
}

.switcher-input:disabled ~ .switcher-indicator::after {
    box-shadow: none
}

.switcher-input:disabled ~ .switcher-label {
    color: #a3a4a6
}

.switchers-stacked::after {
    display: block;
    clear: both;
    content: ""
}

.switchers-stacked .switcher {
    display: block;
    margin-right: 0;
    margin-bottom: .5rem
}

.switcher-square, .switcher-square .switcher-indicator {
    border-radius: .25rem
}

.switcher-square .switcher-indicator::after {
    border-radius: calc(.25rem - 2px)
}

.switcher-sm, .form-group-sm .switcher {
    padding-left: 2rem;
    min-height: 1.125rem;
    font-size: .75rem;
    line-height: 1.486
}

.switcher-sm .switcher-indicator, .form-group-sm .switcher .switcher-indicator {
    width: 2rem;
    height: 1.125rem;
    font-size: .625rem;
    line-height: 1.125rem;
    top: 0
}

.switcher-sm .switcher-label, .form-group-sm .switcher .switcher-label {
    top: .00525rem
}

.switcher-sm .switcher-input:checked ~ .switcher-indicator::after, .form-group-sm .switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: .875rem
}

.switcher-sm .switcher-indicator::after, .form-group-sm .switcher .switcher-indicator::after {
    top: 0;
    margin: .25rem 0 0 .25rem;
    width: .625rem;
    height: .625rem
}

.switcher-sm .switcher-no, .form-group-sm .switcher .switcher-no {
    padding-right: .25rem;
    padding-left: .875rem
}

.switcher-sm .switcher-yes, .form-group-sm .switcher .switcher-yes {
    padding-right: .875rem;
    padding-left: .25rem
}



.switcher-lg, .form-group-lg .switcher {
    padding-left: 3.25rem;
    min-height: 1.75rem;
    font-size: 1rem;
    line-height: 1.47
}

.switcher-lg .switcher-indicator, .form-group-lg .switcher .switcher-indicator {
    width: 3.25rem;
    height: 1.75rem;
    font-size: .625rem;
    line-height: 1.75rem;
    top: 0
}

.switcher-lg .switcher-label, .form-group-lg .switcher .switcher-label {
    top: .14rem
}

.switcher-lg .switcher-input:checked ~ .switcher-indicator::after, .form-group-lg .switcher .switcher-input:checked ~ .switcher-indicator::after {
    left: 1.5rem
}

.switcher-lg .switcher-indicator::after, .form-group-lg .switcher .switcher-indicator::after {
    top: 0;
    margin: .25rem 0 0 .25rem;
    width: 1.25rem;
    height: 1.25rem
}

.switcher-lg .switcher-no, .form-group-lg .switcher .switcher-no {
    padding-right: .25rem;
    padding-left: 1.5rem
}

.switcher-lg .switcher-yes, .form-group-lg .switcher .switcher-yes {
    padding-right: 1.5rem;
    padding-left: .25rem
}


.switcher-secondary.switcher .switcher-input:checked ~ .switcher-indicator {
    background: #8897AA;
    color: #fff
}

.switcher-secondary.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(136,151,170,0.4)
}

.switcher-secondary.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher-success.switcher .switcher-input:checked ~ .switcher-indicator {
    background: #02BC77;
    color: #fff
}

.switcher-success.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(2,188,119,0.4)
}

.switcher-success.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher-info.switcher .switcher-input:checked ~ .switcher-indicator {
    background: #28c3d7;
    color: #fff
}

.switcher-info.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(40,195,215,0.4)
}

.switcher-info.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher-warning.switcher .switcher-input:checked ~ .switcher-indicator {
    background: #FFD950;
    color: #665720
}

.switcher-warning.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(255,217,80,0.4)
}

.switcher-warning.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher-danger.switcher .switcher-input:checked ~ .switcher-indicator {
    background: #d9534f;
    color: #fff
}

.switcher-danger.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(217,83,79,0.4)
}

.switcher-danger.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher-dark.switcher .switcher-input:checked ~ .switcher-indicator {
    background: rgba(24,28,33,0.9);
    color: #fff
}

.switcher-dark.switcher .switcher-input:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(24,28,33,0.4)
}

.switcher-dark.switcher .switcher-input:active ~ .switcher-indicator {
    box-shadow: none
}

.switcher .valid-feedback, .switcher .invalid-feedback {
    padding-left: .5rem
}

.was-validated .switcher-input[class]:valid:checked ~ .switcher-indicator, .switcher-input[class].is-valid:checked ~ .switcher-indicator {
    background: #02BC77;
    color: #fff
}

.was-validated .switcher-input[class]:valid:focus ~ .switcher-indicator, .switcher-input[class].is-valid:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(2,188,119,0.4)
}

.was-validated .switcher-input[class]:valid:active ~ .switcher-indicator, .switcher-input[class].is-valid:active ~ .switcher-indicator {
    box-shadow: none
}

.was-validated .switcher-input[class]:valid ~ .switcher-label, .switcher-input[class].is-valid ~ .switcher-label {
    color: #02BC77
}

.was-validated .switcher-input[class]:valid ~ .valid-feedback, .was-validated .switcher-input[class]:valid ~ .valid-tooltip, .switcher-input[class].is-valid ~ .valid-feedback, .switcher-input[class].is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .switcher-input[class]:invalid:checked ~ .switcher-indicator, .switcher-input[class].is-invalid:checked ~ .switcher-indicator {
    background: #d9534f;
    color: #fff
}

.was-validated .switcher-input[class]:invalid:focus ~ .switcher-indicator, .switcher-input[class].is-invalid:focus ~ .switcher-indicator {
    box-shadow: 0 0 0 2px rgba(217,83,79,0.4)
}

.was-validated .switcher-input[class]:invalid:active ~ .switcher-indicator, .switcher-input[class].is-invalid:active ~ .switcher-indicator {
    box-shadow: none
}

.was-validated .switcher-input[class]:invalid ~ .switcher-label, .switcher-input[class].is-invalid ~ .switcher-label {
    color: #d9534f
}

.was-validated .switcher-input[class]:invalid ~ .invalid-feedback, .was-validated .switcher-input[class]:invalid ~ .invalid-tooltip, .switcher-input[class].is-invalid ~ .invalid-feedback, .switcher-input[class].is-invalid ~ .invalid-tooltip {
    display: block
}


















.table-striped-custom {
    color: var(--font-color);
    border-style: none;
    border: none;
    font-size: 12px;
}

.table-striped-custom thead {
    background-color: var(--secondary-background);
    color: var(--font-color);
}

.table-striped-custom tbody,
.table-striped-custom td,
.table-striped-custom tfoot,
.table-striped-custom th,
.table-striped-custom thead,
.table-striped-custom tr {
    border-color: none;
    border-style: none;
    padding: 6px 8px 6px 8px;
}

.table-striped-custom tbody {
    border-style: none !important;
}

.table-striped-custom tbody tr:nth-of-type(odd) {
    background-color: var(--primary-background);
    border: none;
    color: var(--font-color);
}

.table-striped-custom tbody tr:nth-of-type(even) {
    background-color: var(--secondary-background);
    border: none;
    color: var(--font-color)
}




// *******************************************************************************
// * Badges

@mixin badge-variant($bg) {
}

@mixin appwork-badge-variant($parent, $bg, $color: null) {
    #{$parent} {
        background-color: $bg;
        color: if($color, $color, yiq($bg));
    }
    /*
    #{$parent}[href] {
        @include hover-focus {
            background-color: if(alpha($bg) == 1, rgba-to-hex(rgba($bg, .95), #000), rgba($bg, alpha($bg) + .03));
            color: if($color, $color, yiq($bg));
            text-decoration: none;
        }
    }*/

    .btn #{$parent} {
        background: $bg !important;
        border-color: $bg !important;
        color: if($color, $color, yiq($bg)) !important;
    }
}

@mixin appwork-badge-outline-variant($parent, $bg, $color: $bg) {
    #{$parent} {
        background-color: transparent;
        box-shadow: 0 0 0 1px $bg inset;
        color: $color;
    }
    /*
    #{$parent}[href] {
        @include hover-focus {
            color: $color;
            text-decoration: none;
        }
    }
        */

    .btn #{$parent} {
        background-color: transparent !important;
        box-shadow: 0 0 0 1px $bg inset !important;
        color: $color !important;
    }
}



// I got these from "Variables Dark" scss file
$blue: #1e70cd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #FEB744 !default;
$yellow: #FFD950 !default;
$green: #02BC77 !default;
$teal: #20c997 !default;
$cyan: #28c3d7 !default;


$white: #fff !default;
$black: #25282E !default;

$gray-25: rgba($white, .015) !default;
$gray-50: rgba($white, .03) !default;
$gray-100: rgba($white, .06) !default;
$gray-200: rgba($white, .1) !default;
$gray-300: rgba($white, .2) !default;
$gray-400: rgba($white, .3) !default;
$gray-500: rgba($white, .4) !default;
$gray-600: rgba($white, .5) !default;
$gray-700: rgba($white, .6) !default;
$gray-800: rgba($white, .8) !default;
$gray-900: rgba($white, .9) !default;

$body-color: $white !default;

$theme-colors: ( "primary": $indigo, "secondary": #8897AA, "success": $green, "info": $cyan, "warning": $yellow, "danger": $red, "dark": $black ) !default;

$badge-default-bg: $gray-200 !default;
$badge-outline-default-color: $body-color !default;
$dark-style: true;



@each $color, $value in $theme-colors {
    @if $color != primary and $color != light {
        @include appwork-badge-variant('.badge-#{$color}', if($color=='dark' and $dark-style, $white, $value));
        @include appwork-badge-outline-variant('.badge-outline-#{$color}', if($color=='dark' and $dark-style, $white, $value));
    }
}

@include appwork-badge-variant('.badge-default', $badge-default-bg, $body-color);
@include appwork-badge-outline-variant('.badge-outline-default', $badge-default-bg, $badge-outline-default-color);
