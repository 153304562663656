
.layout-wrapper,
.layout-inner {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.layout-offcanvas .layout-wrapper,
.layout-fixed-offcanvas .layout-wrapper {
    overflow: hidden;
}

.layout-inner {
    min-height: 100vh;
}

.layout-container,
.layout-content,
.layout-content > *,
.layout-sidenav {
    min-height: 1px;
}

.layout-container {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    padding: 0;

    .layout-without-sidenav & {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.layout-content {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
}

.layout-navbar,
.layout-footer {
    flex: 0 0 auto;
}

.layout-navbar {
    position: relative;
    z-index: 2;

    .navbar {
        transform: translate3d(0, 0, 0);
    }
}

.layout-sidenav {
    position: relative;
    flex: 1 0 auto;

    .sidenav {
        transform: translate3d(0, 0, 0);
    }

    .sidenav-vertical {
        height: 100%;
    }
}

.layout-sidenav .sidenav-vertical,
.layout-sidenav.sidenav-vertical {
    align-items: flex-start;
}

html:not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical {
    min-height: 100%;
}

.layout-2 {
    .layout-container {
        background-color: #f5f5f5;
        flex-basis: 100%;
        flex-direction: column;
        width: 0;
        min-width: 0;
        max-width: 100%;
    }

    .layout-content {
        // flex-basis: 100%;
        width: 100%;
    }
}




.authentication-wrapper {
    display: flex;
    justify-content: center;
}